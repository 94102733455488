<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:navigableDetail="false"
			readOnly
		></pui-datatable>
	</div>
</template>

<script>
export default {
	name: 'mgeoreptypes-grid',
	components: {},
	data() {
		return {
			modelName: 'mgeoreptypes',
			modelColumnDefs: {}
		};
	}
};
</script>
